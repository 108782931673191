import config from "../config"

const apiServiceEndpoint = config.serverUri

const getUserAlerts = userId => {
  return new Promise((resolve, reject) => {
    fetch(`${apiServiceEndpoint}/user-alerts/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.ok) {
          response.json().then(data => {
            resolve(data)
          })
        } else {
          response.json().then(data => {
            reject(data)
          })
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

const markAlertAsViewed = (userId, alertId) => {
  if (!userId || !alertId) return
  return new Promise((resolve, reject) => {
    fetch(`${apiServiceEndpoint}/view-alert`, {
      method: "POST",
      body: JSON.stringify({
        userId: userId,
        alertId: alertId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.ok) {
          resolve("Alerta marcada como vista exitosamente.")
        } else {
          response.json().then(data => {
            reject(data)
          })
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

export { getUserAlerts, markAlertAsViewed }
